:root {
  --white: #ffffff;
  --grey: #6c757d;
  --grey2: #b5b5b5;
  --grey3: #e5e5e5;
  --black: #212529;
  --blackInput: #24242A;
  --blackPay: #272631;
  --purple: #E37CFF;
  --blue: #42a5f5;
  --blueHover: #31A6C4;
  --red: #D03235;
  --green: #35d298;
  --yellow: #d2b035;
  --logoBlue: #01AFDF;
  --logoGreen: #15F4BC;
  --hover-brightness: 1.2;
  --hover-darkness: 0.8;
}

a {
  color: var(--black)
}

a:hover {
  color: var(--black)
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

.center {
  display: flex;
  justify-content: center;
}

.fullscreen {
  min-height: 100vh !important;
  justify-content: center;
  display: flex;
}

.bg-primary {
  background-color: var(--blue)
}

.rounded {
  border-radius: 100%;
  max-height: 455px;
  max-width: 455px;
  width: 100%;
  height: 100%;
  height: auto;
}

.roundedWhite {
  padding: 1rem;
  background-color: var(--white);
  border-radius: 100%;
  display: inline-block;
  max-height: 455px;
  max-width: 455px;
  width: 100%;
  height: 100%;
}

.container {
  max-width: 1320px;
  margin: auto;
  z-index: 2;
}

.container-scrolling {
  max-width: 1320px;
  margin: auto;
  overflow: hidden;
  display: flex;
  height: 100px;
  gap: 100px;
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-2430px, 0, 0);
    /* The image width */
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

.arrow {
  position: absolute;
  z-index: 3;
  bottom: 20px;
  left: 50%;
  animation: fadeInDown 2s infinite;
  margin-left: -25px;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: var(--blue);
  opacity: 0.90;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 130px;
  position: relative;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
}

.centerVert {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-weight: 300;
  font-size: 3rem;
  text-transform: uppercase;
  margin: 0;
}

.Typewriter__wrapper {
  font-weight: 600;
  font-size: 6.5rem;
}

.Typewriter__cursor {
  font-weight: 600;
  font-size: 6.5rem;
}

.section {
  position: relative;
  padding: 5rem 0;
  overflow: hidden;
}

.title2 {
  font-weight: 600;
  font-size: 2.5rem;
  margin: 0 0 5rem 0;
  padding: 0 10px;
  text-align: center;
}

.title3 {
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0;
}

.title4 {
  font-weight: 600;
  font-size: 2rem;
  margin: 0;
}

.url {
  text-decoration: none;
}

.url:hover {
  color: var(--blue)
}

.text {
  font-weight: 300;
  font-size: 1rem;
  color: var(--grey);
  margin: 0;
}

.text2 {
  font-weight: 300;
  font-size: 1.5rem;
  color: var(--grey);
  margin: 0;
}

.text3 {
  font-weight: 300;
  font-size: 1.3rem;
  margin: 0;
}


.logoAzienda {
  height: 60px;
  animation: slide 15s linear infinite;
}

.tooltip {
  background-color: var(--grey);
}

.dot {
  width: 160px;
  height: 160px;
  position: absolute;
  border-radius: 100%;
  background-color: var(--blue);
  z-index: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  margin-top: auto;
  bottom: 0;
  margin-bottom: auto;
}

.button {
  border: none;
  background-color: var(--black);
  color: var(--white);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  min-width: 130px;
  padding: 12px 30px;
  text-decoration: none;
  border-radius: 5px;
}

.button:hover {
  cursor: pointer;
  color: var(--white);
}

.clear {
  border: none;
  color: var(--black);
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.clear:hover {
  cursor: pointer;
  color: var(--black);

}

.wrapperYT {
  position: absolute;
  z-index: 0;
  min-width: 100%;
  min-height: 100%;
  left: 0px;
  top: 0px;
  overflow: hidden;
  opacity: 1;
  background-image: none;
  transition-property: opacity;
  transition-duration: 1000ms;
}

.bigNumber {
  font-weight: 600;
  font-size: 8rem;
  z-index: 1;
  margin: 0;
}

.aboutTitle {
  font-weight: 500;
  font-size: 2rem;
  margin: 0px;
}

.age {
  margin: 0px 5px;
  padding: 0px;
  font-size: 1.5rem;
}

.containerAge {
  position: relative;
  height: 600px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1020;
  transition: all 0.5s ease;
}

.navbar {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: none;
  display: flex;
}

.navbarScroll {
  background-color: var(--white);
  border-bottom: 1px solid #efefef !important;
}

.navbarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1320px;
  width: 100%;
  margin: auto;
  padding: 5px 0;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbarOverlay {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  pointer-events: auto;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.95);
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.3s ease, opacity 0.3s ease;
}

.navbarOpen {
  opacity: 1;
  visibility: visible;
}

.navbarCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.navItem {
  text-align: center;
  max-width: 400px;
  border: 0px none;
  height: auto;
  padding: 8px 0;
  position: relative;
  font-weight: 600;
  font-size: 21px;
  text-decoration: none;
  color: var(--white);
}

.navItem:hover {
  color: var(--blue);
}

@media (max-width: 850px) {

  .Typewriter__wrapper {
    font-size: 2.5rem;
  }

  .Typewriter__cursor {
    font-size: 2.5rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .rounded {
    max-height: 200px;
    max-width: 200px;
  }

  .roundedWhite {
    max-height: 200px;
    max-width: 200px;
  }

  .row {
    padding: 0 15px;
    gap: 30px;
    margin-top: 20px;
  }

  .section {
    padding: 3rem 15px;
  }

  .title2 {
    font-size: 2rem;
    margin: 0 0 3rem 0;
  }

  .text3 {
    font-size: 1.1rem;
  }

  .title4 {
    font-size: 1.5rem;
  }

  .aboutTitle {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .age {
    font-size: 1rem;
  }

  .containerAge {
    height: 400px;
  }

  .button {
    font-size: 14px;
  }

  .navbarContainer {
    padding: 5px 15px;
  }

}